import React, { useState, useEffect } from "react";
import { color, motion } from "framer-motion";
import { FaArrowRight, FaPlus } from 'react-icons/fa';
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import pavingwork from "../assets/homeimg.jpg";
import lightingwork from "../assets/lightingwork.jpg";
import grass from "../assets/grass.jpg";
import retainingwalls from "../assets/retainingwalls.jpg";
import systemirrigation from "../assets/systemirrigation.jpg";
import drivewaywork from "../assets/drivewaywork.jpg";


const Services = () => {
  const images = [bg2, bg3];
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="text-center jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#043021] bg-opacity-20 flex items-center justify-center flex-col">
          <motion.h1
            className="text-white text-4xl font-bold mb-4"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Our Services
          </motion.h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="sms:+12062552076"
              className="bg-[#043021] text-white px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>(206) 255-2076</span>
            </a>
          </motion.div>
        </div>
      </motion.div>

      <div className="bg-[#043021] py-10 px-4 text-center">
        <h2 className="text-white text-3xl font-bold mb-4">
          How do I make my garden look better than my neighbor's?
        </h2>

        <div className="flex flex-col md:flex-row justify-around items-center ">
          <ServiceProcess number="1" title="Call or Text Us" className="text-[#043021]"/>
          <FaArrowRight className="text-white text-3xl mx-4 hidden md:block" />
          <ServiceProcess number="2" title="Get a Free Quote" className="text-[#043021]"/>
          <FaArrowRight className="text-white text-3xl mx-4 hidden md:block" />
          <ServiceProcess number="3" title="Let Us Work our Magic!" className="text-[#043021]" />
        </div>
      </div>

      <motion.div
        className="max-w-4xl mx-auto text-043021 px-4 pt-4 pb-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4 text-white">
          <ServiceCard title="Paving Work" backgroundImage={pavingwork} />
          <ServiceCard title="Retaining Walls" backgroundImage={retainingwalls} />
          <ServiceCard title="System Irrigation" backgroundImage={systemirrigation} />
          <ServiceCard title="Natural & Synthetic Grass Installations" backgroundImage={grass} />
          <ServiceCard title="Driveway Work" backgroundImage={drivewaywork} />
          <ServiceCard title="Lighting Work" backgroundImage={lightingwork} />
        </div>
      </motion.div>
    </div>
  );
};

const ServiceProcess = ({ number, title }) => (
  <motion.div
    className="mb-4 md:mb-0 flex flex-col items-center"
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
  >
    <div className="bg-white p-4 rounded-full text-2xl mb-2">{number}</div>
    <h3 className="text-xl font-semibold mb-1 text-white">{title}</h3>
  </motion.div>
);

const ServiceCard = ({ title, backgroundImage }) => {
  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <motion.div
      className="bg-043021 bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300 h-40 w-full"
      style={cardStyle}
      whileHover={{ scale: 1.05 }}
    >
      <h2 className="text-3xl font-semibold mb-2 text-white">{title}</h2>
    </motion.div>
  );
};


export default Services;
