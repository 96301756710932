import React from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import homeimg from "../assets/homeimg.jpg";
import { GiGardeningShears } from "react-icons/gi";
import logo from "../assets/logo.png";
import { FaArrowRight } from "react-icons/fa";

const Home = () => {
  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 1000], [0, 0], { clamp: false });

  return (
    <div className="text-center jost">
      <motion.div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bg1})`, y: y1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#043021] bg-opacity-20 flex flex-col justify-center items-center sm:items-start sm:pl-14 sm:pr-10 sm:py-20">
          <img
            src={logo}
            alt="Logo"
            className="h-24 w-24 mb-4 mr-2 rounded-md sm:hidden"
          />
          <h1 className="text-white text-4xl md:text-6xl font-bold text-center sm:text-left mr-2 sm:pr-3 pb-2">
            Emerald Garden Pavers
          </h1>
          <h3 className="text-white text-xl md:text-2xl pr-2 mb-4 text-center sm:text-left">
            We'll make your garden look better than your neighbor's!
          </h3>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4 text-center sm:text-left"
          >
            <a
              href="sms:+12062552076"
              className="bg-[#043021] text-white px-4 py-2 rounded font-bold flex items-center space-x-1 text-lg md:text-xl"
            >
              <span>📞</span>
              <span>(206) 255 2076</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="relative h-auto sm:pt-8 bg-gray-100">
        <div className="flex flex-row justify-center items-center text-[#043021] text-4xl font-bold w-full space-y-6 p-6 ">
          <h1 className="text-4xl font-bold text-center pt-4 sm:pt-0">
            {" "}
            Making your garden better than your neighbor's!😍
          </h1>
        </div>
        <motion.div
          whileHover={{
            scale: 1.05,
            translateZ: 50,
            perspective: 1000,
            boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
            transition: { duration: 0.2 },
          }}
          className="inline-block sm:mb-8 text-center sm:text-left bg-gray-100"
        >
          <a
            href="/gallery"
            className="bg-[#043021] text-white px-4 py-2 rounded font-bold flex items-center space-x-1 text-md md:text-xl"
          >
            <GiGardeningShears /> <span>View Other Work</span>
          </a>
        </motion.div>
      </div>
      <div className="relative h-auto bg-gray-100 py-10">
        <div className="w-full bg-[#043021] py-10 text-center">
          <h2 className="text-white text-3xl font-bold mb-4">
            How do I make my garden look better than my neighbor's?
          </h2>
          <div className="flex flex-col md:flex-row justify-between items-center px-6">
            <ServiceProcess
              number="1"
              title="Call or Text Us"
              className="text-[#043021]"
            />
            <FaArrowRight className="text-white text-3xl mx-4 hidden md:block" />
            <ServiceProcess
              number="2"
              title="Get a Free Quote"
              className="text-[#043021]"
            />
            <FaArrowRight className="text-white text-3xl mx-4 hidden md:block" />
            <ServiceProcess
              number="3"
              title="Let Us Work our Magic!"
              className="text-[#043021]"
            />
          </div>
        </div>
      </div>
      <div className="relative h-auto sm:py-16 bg-gray-100">
        <div className="flex flex-col-reverse sm:flex-row justify-center items-start sm:space-x-8 px-6 sm:px-20">
          <div className="relative flex flex-col sm:w-1/2 mt-8 sm:mt-0 space-y-4 order-2 sm:order-1">
            <div className="absolute top-0 left-0 w-full h-[2px] bg-[#043021] sm:mt-2"></div>
            <div className="relative pt-6 sm:pt-4 pl-4 sm:pl-0 text-[#043021]">
              <h1 className=" text-4xl font-bold text-left pb-6 py-8">
                Why Choose Emerald Garden Pavers?
              </h1>
              <h2 className="text-2xl font-bold text-left pb-6 py-8">
                What We Offer:
              </h2>
              <div className="text-left space-y-2 text-xl pb-6">
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  • Free Estimates.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  • Good References w/Testimonials.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  • The highest level of work.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                >
                  • Over 6 years of experience.
                </motion.div>
              </div>
              <p className="text-xl mt-4 pb-5 sm:pb-0 text-left">
                <span className="underline underline-offset-2 font-bold">
                  About Us:
                </span>{" "}
                Emerald Garden Pavers Offers Paving Work, Retaining Walls,
                System Irrigation, Natural & Synthetic Grass Installations,
                Driveway Work, Lights Work.{" "}
              </p>
            </div>
          </div>
          <div className="relative flex flex-col w-full sm:w-1/2 space-y-4 sm:space-y-0 sm:space-x-4 order-1 sm:order-2">
            <motion.div
              className="relative w-full"
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <img
                src={homeimg}
                alt="Image 1"
                className="w-full object-cover p-2"
                style={{ zIndex: 1 }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Define ServiceProcess outside the Home component
const ServiceProcess = ({ number, title }) => (
  <motion.div
    className="flex-1 mb-4 md:mb-0 flex flex-col items-center"
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
  >
    <div className="bg-white p-4 rounded-full text-2xl mb-2">{number}</div>
    <h3 className="text-xl font-semibold mb-1 text-white">{title}</h3>
  </motion.div>
);

export default Home;
