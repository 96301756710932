import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import img1 from "../assets/gallery/img1.JPG";
import img2 from "../assets/gallery/img2.JPG";
import img3 from "../assets/gallery/img3.JPG";
import img4 from "../assets/gallery/img4.JPG";
import img5 from "../assets/gallery/img5.JPG";
import img6 from "../assets/gallery/img6.JPG";
import img7 from "../assets/gallery/img7.JPG";
import img8 from "../assets/gallery/img8.JPG";
import img9 from "../assets/gallery/img9.JPG";
import img10 from "../assets/gallery/img10.JPG";
import img11 from "../assets/gallery/img11.JPG";
import img12 from "../assets/gallery/img12.JPG";
import img13 from "../assets/gallery/img13.JPG";
import img14 from "../assets/gallery/img14.JPG";
import img15 from "../assets/gallery/img15.JPG";
import img16 from "../assets/gallery/img16.JPG";
import img17 from "../assets/gallery/img17.JPG";
import img18 from "../assets/gallery/img18.JPG";
import img19 from "../assets/gallery/img19.JPG";
import img20 from "../assets/gallery/img20.JPG";
import img21 from "../assets/gallery/img21.JPG";
import img22 from "../assets/gallery/img22.JPG";
import img23 from "../assets/gallery/img23.JPG";
import img24 from "../assets/gallery/img24.JPG";
import img25 from "../assets/gallery/img25.JPG";
import img26 from "../assets/gallery/img26.JPG";
import img27 from "../assets/gallery/img27.JPG";
import img28 from "../assets/gallery/img28.JPG";
import img29 from "../assets/gallery/img29.JPG";
import img30 from "../assets/gallery/img30.JPG";
import img31 from "../assets/gallery/img31.JPG";
import img32 from "../assets/gallery/img32.JPG";
import img33 from "../assets/gallery/img33.JPG";
import img34 from "../assets/gallery/img34.JPG";
import img35 from "../assets/gallery/img35.JPG";
import img36 from "../assets/gallery/img36.JPG";
import img37 from "../assets/gallery/img37.JPG";
import img38 from "../assets/gallery/img38.JPG";
import img39 from "../assets/gallery/img39.JPG";
import img40 from "../assets/gallery/img40.JPG";
import img41 from "../assets/gallery/img41.JPG";
import img42 from "../assets/gallery/img42.JPG";
import img43 from "../assets/gallery/img43.JPG";



const bgimages = [bg1, bg2, bg3];
const galleryImages = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40, img41, img42, img43];

const Gallery = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#043021] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-white text-4xl font-bold mb-4 px-6">
            Past Work
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="sms:+12062552076"
              className="bg-[#043021] text-white px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (206) 255-2076 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {galleryImages.map((image, index) => (
            <motion.div
              key={index}
              className="border"
              whileHover={{
                scale: 1.1,
                translateZ: 50,
                perspective: 1000,
                boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              onClick={() => openImage(index)}
            >
              <img
                src={image}
                alt={`Gallery ${index}`}
                className="w-full h-96 object-cover"
              />
            </motion.div>
          ))}
        </div>
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#043021] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Gallery;
